.ac_results {
  text-align: left;
  padding: 0px;
  border: 1px solid black;
  background-color: white;
  overflow: hidden;
  z-index: 49;
}
.ac_results ul {
  width: 100%;
  list-style-position: outside;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ac_results li {
  margin: 0px;
  padding: 2px 5px;
  cursor: default;
  display: block;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
}
.ac_odd {
  background-color: #eee;
}
.ac_over {
  background-color: #0A246A;
  color: white;
}